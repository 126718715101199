<template>
  <v-container
    fill-height
  >
    <v-row
      justify="center"
      align="center"
      class="flex-column"
    >
      <p
        v-translate
        translate-context="Page content (not found page)"
      >
        Oops, page not found!
      </p>

      <v-btn
        outlined
        color="primary"
        :to="{
          name: 'projects-list'
        }"
      >
        <span
          v-translate
          translate-context="Go to homepage button"
        >
          Go to homepage
        </span>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
  export default {};
</script>
